(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('lmsApp')
        .constant('VERSION', "1.4.1")
        .constant('GIT', {
	"message": "Merge branch 'dev' into 'master'\n\nDev\n\nSee merge request lms-mob-edu/lms-legacy/legacy-front!116",
	"commit": "c9eba2035ac7ad929b613f3f013ea7500a04ec51",
	"branch": "master",
	"date": "2023-03-01T15:06:58.000Z"
})
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('ENV', "prod")
        .constant('API', {
	"newAdmin": "/adm",
	"course": "/course"
})
        .constant('innopolis', undefined)
        .constant('innopolis_stage', undefined)
        .constant('edu4', undefined)
;
})();
